import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layouts/Layout";
import { AppContext } from "../App";
import { API_BASE_URL } from "../config";
import axios from "axios";

export default function Home() {
  const { userInfo, setUserInfo } = useContext(AppContext);
  const navigate = useNavigate(); // Hook for navigation

  const showStakingDlg = () => {
    console.log("showStakingDlg");
    navigate("/wallet/1");
  };

  const initLoading = async () => {
    try {
      const token = localStorage.getItem("authorization");
      const { data: userinfo } = await axios.get(`${API_BASE_URL}/user/info`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      });
      console.log("[Userinfo]", userinfo);
      if (userinfo.state) setUserInfo(userinfo.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect (() => {
    console.log("============ init loading ...")
    initLoading();
  })

  return (
    <div className="h-screen p-6 bg-black max-sm:absolute max-sm:top-0 max-sm:left-0 max-sm:right-0 max-sm:bottom-0">
      <div className="flex flex-col justify-between border-4 border-green-400 rounded-xl" style={{height:"-webkit-fill-available"}}>
        <div className="w-full p-4 rounded-lg ">
          <div className="flex flex-col items-center mb-4">
            <img
              src="/images/mark.png"
              alt="Logo"
              className="w-32 h-32 mt-0 mb-0"
            />
            <div className="flex flex-row items-center pt-4">
              <h1 className="mr-4 text-6xl text-gold"> W </h1>
              <div>
                <h2 className="text-lg text-gold"> YOUR WORLD </h2>
                <h2 className="text-3xl font-bold text-gold">
                  {userInfo.balance + userInfo.invite_balance}
                </h2>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <button
              className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
              onClick={() =>
                (window.location.href = "https://whop.com/prosperous-world")
              }
            >
              ACADAMY
            </button>
            <button
              className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
              onClick={() =>
                (window.location.href = " https://whop.com/prosperous-world")
              }
            >
              PRIME
            </button>
            <button
              className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
              onClick={showStakingDlg}
            >
              EARN
            </button>
            <button
              className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
              onClick={() =>
              (window.location.href =
                "www.prosperousworld.com")
              }
            >
              BUY WORLD
            </button>
          </div>
        </div>
        <div className="p-6">
          <Layout></Layout>
        </div>
      </div>
    </div>
  );
}
