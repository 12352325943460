import { Card } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config";
import { AppContext } from "../App";

import axios from "axios";

function InviteCard(item) {

  return (
    <Card className="flex-row items-center w-full gap-2 p-3 pl-5 text-left text-green-400 shadow-none bg-background-light">
      <div className="flex flex-row space-y-1 grow">
        <h5 className="ml-5 space-y-1 font-bold grow">{item.item.metaInfo.userName}</h5>
        <div className="flex flex-row mr-5">
          <h5 className="mr-4">W</h5>
          <h5 className="w-120">{item.item.balance}</h5>
        </div>
      </div>
    </Card>
  );
}

export default function Friends() {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useContext(AppContext);
  const [friends, setFriends] = useState([]);
  const tele = window.Telegram.WebApp;

  const defaultInviteTextEncoded = `World%20App%20-%20where%20every%20World%20is%20the%20genesis%20of%20everything.%0AJoin%20now%20with%20me%20as%20an%20early%20bird%20%E2%80%94%20World%20just%20launched%20with%20huge%20potential%21%0APlant-to-earn%20from%20thousands%20of%20Web3%20projects%20in%20World%20ecosystem%20with%20me.`;

  useEffect(() => {
    const getTasksInfo = async () => {
      try {
        const token = localStorage.getItem("authorization");
        const { data } = await axios.get(`${API_BASE_URL}/user/friend`, {
          headers: {
            Authorization: `bearer ${token}`
          }
        });
        console.log("friends", data);
        if (data?.state) {
          setFriends(data.data);
          setUserInfo(data.user);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getTasksInfo();
  }, []);

  useEffect(() => {
    for (let i = 0; i < friends.length; i++) {
      const item = friends[i]; // Get the current friend item
      console.log("friend_item : ", item);
    }
  }, [friends]);

  const handleShareLink = () => {
    console.log("handleShareLink......", userInfo)
    tele.openTelegramLink(
      `https://t.me/share/url?url=${userInfo?.inviteCode}&text=${defaultInviteTextEncoded}`
    );
  }

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col justify-between h-screen p-6 text-green-400 bg-black max-sm:absolute max-sm:top-0 max-sm:left-0 max-sm:right-0 max-sm:bottom-0">
      <div onClick={goHome} className="absolute flex w-8 h-8 right-10 top-10" style={{ backgroundImage: `url('/images/home.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
      <div className="pl-10">
        <img src="/images/mark.png" alt="Logo" className="w-32 h-32" />
      </div>
      <img
        src="/images/INVITE1.PNG"
        alt="Logo"
        className="inset-0 justify-center w-32 h-20 m-auto mt-3"
      />
      <h1 className="mt-3 text-2xl font-bold text-center text-gold">
        INVITE FRIENDS
      </h1>
      <div className="inset-0 flex flex-row justify-center m-auto mt-2 mb-2">
        <h1 className="mr-4 text-3xl font-bold text-center text-gold">
          W{" "}
        </h1>
        <h1 className="text-3xl font-bold text-center text-gold">
          {userInfo.invite_balance}
        </h1>
      </div>
      <h1 className="mt-4 mb-1 text-center text-gold text-10">
        Get 10% from your friends
      </h1>

      <div className="px-4 mt-2 ml-4 mr-4 space-y-2 overflow-y-scroll h-44">
        {friends.map((item) => (
          // <InviteCard key={item.chatId} {...item} />
          <InviteCard item={item} />
        ))}
      </div>

      <div className="flex justify-center">
        <button
          className="inset-0 flex justify-center px-8 py-1 m-auto text-xl font-bold text-green-400 border-4 border-green-400 rounded-full "
          onClick={handleShareLink}
        >
          Invite a friend
        </button>
      </div>
    </div>
  );
}
